export const getLSItem = (...params) => {
  let retnVal = null;

  try {
    retnVal = window.localStorage.getItem(...params);
  } catch (e) {}

  return retnVal;
};

const filterDuration = (val) => {
  return val < 60000; // 1 minute
};

const fireGoogleAnalyticsEvent = (event, eventData) => {
  if ((NODE_ENV === "production" && typeof window?.gtagOS === "function")) {
    const userData = {};

    try {
      const user = JSON.parse(decodeURIComponent(getLSItem("user")));
      if (user?.id) {
        userData.os_user_id = user?.id;
      }
    } catch (error) {}

    try {
      const shop = JSON.parse(decodeURIComponent(getLSItem("shop")));
      if (shop?.agencyId) {
        userData.os_agency_id = shop?.agencyId;
      }
      if (shop?.id) {
        userData.os_client_id = shop?.id;
      }
    } catch (error) {}

    try {
      const selectedEntityIdNew = getLSItem("selectedEntityIdNew");
      if (selectedEntityIdNew) {
        userData.os_entity_id = selectedEntityIdNew;
      }
    } catch (error) {}

    const dataToSend = {
      send_to: "G-B9DERPVFKB",
      os_domain: window.location.hostname,
      os_ui_type: window?.osUIConfig?.uiType,
      ...userData,
      ...eventData,
    };
    // console.log("fireGoogleAnalyticsEvent", event, dataToSend);
    window.gtagOS("event", event, dataToSend);
  }
};

export const setUserInfo = ({ userId }) => {
  if (NODE_ENV === "production" && typeof window?.gtagOS === "function") {
    window.gtagOS("config", "G-B9DERPVFKB", {
      user_id: userId,
    });
  }
};

export const mesureAPIPerformance = () => {
  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      if (
        entry.initiatorType === "fetch" &&
        entry.name.includes("onlinesales.ai") &&
        !entry.name.includes(".com") &&
        entry.responseStatus === 200
      ) {
        const url = new URL(entry.name);
        const timeToFetch = entry.responseEnd - entry.fetchStart;

        fireGoogleAnalyticsEvent("os_fetch_api", {
          os_api_domain: url.hostname,
          os_api_endpoint: url.pathname,
          os_duration: timeToFetch,
        });
      }
    });
  });

  observer.observe({ type: "resource", bufferxed: true });
};

export const trackMarkerPerformance = (marker, startMark, endMark) => {
  const markerData = performance.measure(marker, startMark, endMark);

  if (filterDuration(markerData.duration)) {
    fireGoogleAnalyticsEvent(markerData.name, {
      os_duration: markerData.duration,
    });
  } else {
    fireGoogleAnalyticsEvent("os_duration_exceeded", {
      os_e_duration: markerData.duration,
      os_exceeded_type: markerData.name,
    });
  }
};

export const trackUIPerformance = () => {
  try {
    const nowTime = performance.now();
    // track domain hit to App.js load
    if (filterDuration(nowTime)) {
      fireGoogleAnalyticsEvent("os_app_load_from_start", {
        os_duration: nowTime,
      });
    } else {
      fireGoogleAnalyticsEvent("os_duration_exceeded", {
        os_e_duration: nowTime,
        os_exceeded_type: "os_app_load_from_start",
      });
    }

    // track index.js load to App.js load
    // track start after index.js is loaded, downloaded and parsed.
    trackMarkerPerformance("os_app_load", "os_app_load_mark");

    const navigationPerformance = performance.getEntriesByType("navigation")?.[0]?.toJSON();

    // track domain hit to index.html load
    if (navigationPerformance) {
      const time = navigationPerformance.responseEnd - navigationPerformance.startTime;
      if (filterDuration(time)) {
        fireGoogleAnalyticsEvent("os_index_html_loaded", {
          os_duration: time,
        });
      } else {
        fireGoogleAnalyticsEvent("os_duration_exceeded", {
          os_e_duration: time,
          os_exceeded_type: "os_index_html_loaded",
        });
      }
    }
  } catch (error) {}
};

export const trackUIDashboardLoad = () => {
  try {
    const nowTime = performance.now();
    // track domain hit to App.js load
    if (filterDuration(nowTime)) {
      fireGoogleAnalyticsEvent("os_app_dashboard_load", {
        os_duration: nowTime,
      });
    } else {
      fireGoogleAnalyticsEvent("os_duration_exceeded", {
        os_e_duration: nowTime,
        os_exceeded_type: "os_app_dashboard_load",
      });
    }
  } catch (error) {}
};

export const startPerformanceTracking = () => {
  mesureAPIPerformance();
};
